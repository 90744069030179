<template>
  <div style="height: inherit" class="user-store-details store-ui px-md-2" :class="{ 'p-0': $store.getters['app/windowSize'] <= 767 }">
    <login-modal ref="loginModal" />
    <template v-if="$store.getters['app/windowSize'] > 767">
      <div class="store-ui mt-2">
        <div class="row">
          <div class="col-md-12 p-sm-0 d-flex flex-wrap">
            <b-row class="d-flex justify-content-between w-100">
              <b-col cols="12" :md="otherPhotos.length > 0 ? 6 : 12" class="pr-0">
                <b-img
                  class="left-image cursor-pointer border-radius-10"
                  :src="mainPhoto ? $helpers.imageHelper(mainPhoto) : require('@/assets/images/app-images/banner-store-image.webp')"
                  @click="
                    showPopup = true
                    showPopupImage($helpers.imageHelper(mainPhoto))
                  "
                  @error="$helpers.imageError"
                />
              </b-col>
              <b-col v-if="otherPhotos.length > 0" cols="12" md="6" class="d-flex justify-content-end pr-0">
                <b-row class="d-flex flex-wrap grid-row-gap-5">
                  <b-col cols="6" class="align-self-end px-0" v-for="(item, index) of otherPhotos.slice(0, 4)" :key="`${index}_other_images`">
                    <div class="pl-2">
                      <b-img :src="$helpers.imageHelper(item.photo)" @click="showPopupImage(item.photo)" class="right-side-images border-radius-10" @error="$helpers.imageError" />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-card class="mt-3 main_card w-100">
          <div class="row w-100">
            <div class="col-12 mb-2">
              <div class="row d-flex align-items-center">
                <div class="col-12 col-md-8">
                  <h1 class="store_title d-flex align-items-center">
                    <img v-if="storeList.picture" :src="$helpers.imageHelper(storeList.picture)" @error="$helpers.imageError" fluid class="store-logo mr-1" />
                    <span>{{ storeList.store_name }}</span>
                  </h1>
                </div>
                <div class="col-12 col-md-4 d-flex justify-content-end">
                  <img v-for="star in 5" :key="star" class="profile_page_label_icon" :class="{ 'gray-scale-icon': star > storeList.rating }" :src="require('@/assets/images/app-icons/rating.png')" />
                  <img class="cursor-pointer ml-2 profile_page_label_icon" :src="require('@/assets/images/app-icons/Share.png')" @click="copyUrl" />
                </div>
              </div>
            </div>
            <div class="col-12 w-100">
              <div class="row w-100">
                <div class="col-12 col-md-6">
                  <div v-if="storeList.contact_number" class="my-1 d-flex justify-content-start">
                    <img :src="require('@/assets/images/app-icons/Call.png')" class="mr-2 profile_page_label_icon" />
                    <p class="store_phone fs-1p5rem cursor-pointer" @click="openPhone">+{{ storeList.country_code }}{{ storeList.contact_number }}</p>
                  </div>
                  <div class="row my-1">
                    <div class="col-12 d-flex justify-content-between mb-1">
                      <div class="row d-flex align-content-center flex-wrap pl-1">
                        <div v-if="storeList.facebook" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-facebook.svg')"
                            @click="goToSocial(`${storeList.facebook}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.instagram" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-instagram.svg')"
                            @click="goToSocial(`${storeList.instagram}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.linkedin" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-linkedin.svg')"
                            @click="goToSocial(`${storeList.linkedin}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.telegram" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-telegram-app.svg')"
                            @click="goToSocial(`${storeList.telegram}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.twitter" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-twitter.png')"
                            @click="goToSocial(`${storeList.twitter}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.whatsapp" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-whatsapp.svg')"
                            @click="goToSocial(`${storeList.whatsapp}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.messenger" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-facebook-messenger.svg')"
                            @click="goToSocial(`${storeList.messenger}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.tiktok" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-tiktok.svg')" @click="goToSocial(`${storeList.tiktok}`, '_BLANK')" />
                        </div>
                        <div v-if="storeList.viber" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-viber.svg')" @click="goToSocial(`${storeList.viber}`, '_BLANK')" />
                        </div>
                        <div v-if="storeList.youtube" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-youtube.svg')"
                            @click="goToSocial(`${storeList.youtube}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.pintrest" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-pinterest.svg')"
                            @click="goToSocial(`${storeList.pintrest}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.skype" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-skype.svg')" @click="goToSocial(`${storeList.skype}`, '_BLANK')" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="storeList.offer_min_amount" class="my-1 d-flex justify-content-start">
                    <img :src="require('@/assets/images/app-icons/shopping-bag.png')" class="mr-2 profile_page_label_icon" />
                    <p class="store_phone fs-1p5rem">{{ $t('Minimum order value') }} {{ storeList.currency_symbol }} {{ storeList.offer_min_amount }}</p>
                  </div>
                  <div v-if="storeTimings && storeList.product_type == 1" class="my-1 d-flex justify-content-start">
                    <img :src="require('@/assets/images/app-icons/Timing.png')" class="mr-2 profile_page_label_icon" />
                    <p class="store_phone fs-1p5rem cursor-pointer" @click="toggleTimings = !toggleTimings">
                      Today: {{ storeTimings.store_start_time.slice(0, 5) }} - {{ storeTimings.store_end_time.slice(0, 5) }}
                      <feather-icon :icon="toggleTimings ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="15" />
                    </p>
                  </div>
                  <div v-if="storeTimings && storeList.product_type == 1 && toggleTimings" class="my-1 d-flex justify-content-start ml-5">
                    <table class="table table-borderless">
                      <tbody>
                        <tr v-for="(data, index) of weekDays" :key="index">
                          <th>
                            {{ data }}
                          </th>
                          <td v-if="storeTimingData(data)">{{ storeTimingData(data).store_start_time.slice(0, 5) }} - {{ storeTimingData(data).store_end_time.slice(0, 5) }}</td>
                          <td v-else>Closed</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="storeList.product_type == 1" class="my-1 d-flex justify-content-start">
                    <img class="mr-2 profile_page_label_icon" :src="require('@/assets/images/app-icons/express-delivery.png')" />
                    <p class="store_phone fs-1p5rem">
                      {{ $t('Delivery within') }} {{ storeList.estimated_delivery_time ? storeList.estimated_delivery_time : 'NA' }}
                      <span style="text-transform: lowercase">{{ storeList.estimated_delivery_time_type }}</span>
                    </p>
                  </div>
                  <div v-if="storeList.product_type == 1" class="my-1 d-flex justify-content-start">
                    <b-button v-if="storeList.dine_in" variant="outline-dark" class="mr-1 btn-store-profile fs-1rem">
                      <b-img height="25" class="mr-1" :src="require('@/assets/images/app-icons/shop.png')" />
                      {{ $t('DINE IN') }}
                    </b-button>
                    <b-button variant="outline-dark" class="mr-1 btn-store-profile fs-1rem">
                      <b-img height="25" class="mr-1" :src="require('@/assets/images/app-icons/take-away.png')" />
                      {{ $t('STORE PICKUP') }}
                    </b-button>
                    <b-button variant="outline-dark fs-1rem">
                      <b-img height="25" class="mr-1 btn-store-profile" :src="require('@/assets/images/app-icons/express-delivery.png')" />
                      {{ $t('DELIVERY') }}
                    </b-button>
                  </div>
                </div>
                <div v-if="storeList.product_type == 1" class="col-12 col-md-6">
                  <div class="row w-100">
                    <div v-if="mapRoute" class="col-12 w-100">
                      <iframe :src="mapRoute" style="width: 100%; height: 300px; border: 0" />
                    </div>
                    <div class="col-12 mt-2 w-100">
                      <div class="d-flex align-items-center">
                        <b-img height="40" :src="`${$apiUrl}/images/icons/location.svg`" class="mr-1" />
                        <p class="store_location fs-1p5rem mb-0 cursor-pointer" @click="openStoreMap">
                          {{ storeList.store_location }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
        <b-card class="mt-4 tab_card">
          <div class="store-ui-main-descriptions">
            <b-tabs pills class="user-profile-info d-flex flex-wrap justify-content-center mb-5 store-details-tabs" @activate-tab="tabActivated">
              <b-tab>
                <template #title>
                  <span class="d-inline">{{ $t('OVERVIEW') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.overview" :type="'overview'" />
                <div v-if="optionsLoaded.overview && storeList.overviews" class="wrap-overview">
                  <!-- eslint-disable vue/no-v-html -->
                  <p v-html="storeList.overviews.overview" class="fs-1p2rem" />
                  <!--eslint-enable-->
                </div>
              </b-tab>
              <b-tab active>
                <template #title>
                  <span class="d-inline">{{ $t('ORDER') }}</span>
                </template>
                <div class="wrap-online-order-section">
                  <div class="row">
                    <div class="col-md-2">
                      <store-side-bar
                        :filter-options="filterOptions"
                        :show-filter-close="true"
                        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
                        @hide-filter-option="mqShallShowLeftSidebar = false"
                        @price-range-change="priceRangeChange"
                      />
                    </div>
                    <div v-if="!optionsLoaded.products" class="col-md-10">
                      <no-data-available :type="'product'" />
                    </div>
                    <div v-if="optionsLoaded.products" class="col-md-10">
                      <section id="ecommerce-header">
                        <div class="row">
                          <div v-if="userOffers.length > 0" class="promocodes-wrapper promocodes-wrapper-store row w-100" style="overflow-y: auto; white-space: nowrap; display: block">
                            <user-offer-cards
                              v-for="(coupon, counter) in userOffers"
                              :key="counter"
                              class="menu-category-offer-cards mx-1 mb-0 cursor-pointer col-md-3 d-inline-block px-0"
                              :product="coupon"
                            />
                          </div>
                          <div class="col-sm-12">
                            <div class="ecommerce-header-items">
                              <div class="result-toggler">
                                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" />
                                <div class="search-results fs-16px">{{ $t('Showing') }} {{ filteredStore.length }} {{ $t('of') }} {{ storeList.products ? storeList.products.length : 0 }} {{ $t('results') }}</div>
                              </div>
                              <div class="view-options d-flex">
                                <!-- Item View Radio Button Group  -->
                                <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons size="sm" button-variant="outline-primary">
                                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                                    <feather-icon :icon="option.icon" size="18" />
                                  </b-form-radio>
                                </b-form-radio-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div class="body-content-overlay" />

                      <div class="ecommerce-searchbar mt-1">
                        <b-row>
                          <b-col cols="12">
                            <b-input-group class="input-group-merge">
                              <b-form-input v-model="searchQuery" placeholder="Search..." class="search-product" @keyup="beginSearch" />
                              <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" class="text-muted" />
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                        </b-row>
                      </div>

                      <section :class="itemView">
                        <b-card
                          v-for="product in filteredStore"
                          :key="product.id"
                          class="ecommerce-card"
                          :class="{ 'disable-effect-stock': !product.is_available, 'pt-pb': itemView !== 'list-view' }"
                          style="font-size: 130%"
                          no-body
                        >
                          <div class="item-img text-center set-extra-images">
                            <div class="d-flex extra-image-ui" :class="{ 'justify-content-end': !product.is_veg }">
                              <b-img v-if="product.is_veg" class="food-type-image" :src="$helpers.getIcons(product.is_veg ? (product.is_veg === 'Non Veg' ? 'non-veg' : 'veg') : 'non-veg')" />
                              <div class="wrapper-images d-flex">
                                <b-img v-if="product.is_best_seller === 1" class="best-seller-image" :src="$helpers.getIcons('bestseller')" />
                                <b-img v-if="product.is_recommended === 1" class="recommended-image" :src="$helpers.getIcons('recommended')" />
                              </div>
                            </div>
                            <b-img v-if="!product.is_available" class="out-of-stock-image" :src="$helpers.getIcons('stock-out')" />
                            <div class="w-100">
                              <b-img
                                v-if="itemView === 'list-view'"
                                :alt="`${product.item_name}`"
                                fluid
                                class="card-img-top rounded"
                                :src="$helpers.imageHelper(product.picture)"
                                @error="$helpers.imageError"
                              />
                              <figure v-else @mouseenter="$superApp.helper.zoomMagic" @mouseout="$superApp.helper.zoomOut">
                                <v-zoomer :zoomed.sync="$superApp.zoomed" :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom">
                                  <b-img :alt="`${product.item_name}`" fluid class="card-img-top" :src="$helpers.imageHelper(product.picture)" @error="$helpers.imageError" />
                                </v-zoomer>
                              </figure>
                            </div>
                            <div v-if="!isEmpty(product.brand)" class="extra-image-ui-bottom d-flex justify-content-end">
                              <b-img class="food-type-image" :src="`${$apiUrl}/${product.brand.logo}`" />
                            </div>
                          </div>

                          <!-- Product Details -->
                          <b-card-body>
                            <div class="item-wrapper">
                              <div class="item-rating">
                                <h6 v-if="product.offer" class="item-price fs-16px" style="text-decoration: line-through">{{ storeList.currency_symbol }} {{ product.item_price }}</h6>
                              </div>
                              <div>
                                <h6 class="item-price fs-16px">{{ storeList.currency_symbol }} {{ product.product_offer }}</h6>
                              </div>
                            </div>
                            <h6 class="item-names" :class="{ fs_110x: itemView === 'list-view' }">
                              <b-link class="text-body fs-16px" :class="{ fs_110x: itemView === 'list-view' }">
                                {{ product.item_name }}
                                <span v-if="product.unit_value && product.unit_id && product.main_unit">({{ product.unit_value }} {{ product.main_unit.name }}) </span>
                                <template v-if="!isEmpty(product.brand)">
                                  <span class="text-muted">By {{ product.brand.title }}</span>
                                </template>
                              </b-link>
                            </h6>
                            <b-card-text
                              v-if="product.item_description"
                              v-b-tooltip.hover
                              :title="product.item_description"
                              class="item-description fs-14px d-block text-break auto-text"
                              :class="{ 'mb-0': itemView === 'list-view', fs_110x: itemView === 'list-view' }"
                            >
                              {{ product.showFull ? product.item_description : getText(product.item_description) }}
                              <span
                                v-if="canShowLabel(product.item_description)"
                                class="small font-weight-bold text-right float-right"
                                style="word-break: keep-all !important"
                                @click="product.showFull = !product.showFull"
                              >
                                <feather-icon :icon="product.showFull ? 'ChevronUpIcon' : 'ChevronDownIcon'" class="cursor-pointer" size="15" />
                              </span>
                            </b-card-text>
                            <b-card-text v-if="product.offer && itemView === 'list-view'" class="item-description fs-14px mb-0" style="text-decoration: line-through" :class="{ fs_110x: itemView === 'list-view' }"
                              >{{ $t('Original Price') }}: {{ storeList.currency_symbol }} {{ product.item_price }}</b-card-text
                            >
                            <b-card-text v-if="itemView === 'list-view'" class="item-description fs-14px" :class="{ fs_110x: itemView === 'list-view' }">
                              {{ $t('Offer Price') }}: {{ storeList.currency_symbol }} {{ product.product_offer }}
                            </b-card-text>
                            <!-- <b-card-text
                                v-if="itemView === 'list-view'"
                                class="item-description"
                                :class="{'fs_110x': itemView === 'list-view'}"
                              >
                                <p>{{product.item_description}}</p>
                              </b-card-text> -->
                            <b-card-text v-if="itemView === 'list-view'" class="item-description fs-14px">
                              <b-button v-if="isAddedToCart(product)" :variant="'primary'" class="cart-item-qty btn-cart">
                                <form-spin-button :product="product" :cart-product="multipleProduct(product)" @value-changed="addItemToCart" />
                              </b-button>
                              <b-button v-if="!isAddedToCart(product)" variant="primary" tag="a" class="btn-cart" @click="setItemAddOnData(product)">
                                <span>{{ isAddedToCart(product) ? 'Added to cart' : 'Add' }}</span>
                              </b-button>
                            </b-card-text>
                          </b-card-body>

                          <div v-if="itemView !== 'list-view'" class="item-options text-center">
                            <b-button v-if="isAddedToCart(product)" :variant="'primary'" class="cart-item-qty btn-cart">
                              <form-spin-button :product="product" :cart-product="multipleProduct(product)" @value-changed="addItemToCart" />
                            </b-button>
                            <b-button v-if="!isAddedToCart(product)" variant="primary" tag="a" class="btn-cart" @click="setItemAddOnData(product)">
                              <span>{{ isAddedToCart(product) ? 'Added to cart' : 'Add' }}</span>
                            </b-button>
                          </div>
                        </b-card>
                      </section>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab v-if="isFoodCategory && storeList.book_a_table == 1">
                <template #title>
                  <span class="d-inline">{{ $t('BOOK A TABLE') }}</span>
                </template>
                <div class="wrap-book-a-table">
                  <div class="row container mx-auto mobile-pad-0 mt-3">
                    <div class="col-md-4">
                      <div class="d-flex wrap-store-options align-items-center justify-content-center mx-auto w-50">
                        <b-img style="height: 25px" class="mr-2" :src="require('@/assets/images/app-icons/teamwork.png')" />
                        <p class="mb-0">
                          {{ $t('NO OF GUESTS') }}
                        </p>
                      </div>
                      <b-form-spinbutton v-model="bookTable.no_guests" style="width: 30%" class="mx-auto" :value="1" min="1" size="sm" />
                    </div>
                    <div class="col-md-4 mobile-pad-0">
                      <div class="d-flex wrap-store-options align-items-center justify-content-center mx-auto w-50">
                        <b-img style="height: 25px" class="mr-2" :src="require('@/assets/images/app-icons/Timing.png')" />
                        <p class="mb-0">
                          {{ $t('DATE & TIME') }}
                        </p>
                      </div>
                      <custom-calender ref="bookingCalenderRef" :timing="storeCurrentTimings" :all-timings="storeSlotInfo" :store-slot-info="storeSlotInfo" @submit="bookTableOnline" />
                    </div>
                    <div class="col-md-4">
                      <div class="d-flex wrap-store-options align-items-center justify-content-center mx-auto w-50">
                        <b-img style="height: 25px" class="mr-2" :src="require('@/assets/images/app-icons/comments.png')" />
                        <p class="mb-0">
                          {{ $t('COMMENTS') }}
                        </p>
                      </div>
                      <textarea v-model="bookTable.comment" class="form-control" placeholder="Enter your comments here..." />
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-inline">{{ $t('REVIEW') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.reviews" :type="'reviews'" />
                <div v-if="optionsLoaded.reviews" class="wrap-reviews-info">
                  <div class="d-flex">
                    <div v-if="storeList.reviews" class="col-md-8 store-shadow wrap-store-review">
                      <h3 class="mt-2 fs-16px">
                        {{ $t('All reviews') }}
                      </h3>
                      <div v-for="(item, index) in storeList.reviews" :key="index" class="row reviews fs-14px pt-2 pb-0" :class="{ 'border-bottom': index < storeList.reviews.length }">
                        <div class="col-md-9">
                          <b-media class="pb-50 pt-50 align-items-center fs-14px">
                            <template #aside>
                              <b-avatar />
                            </template>
                            <h6 class="mb-0 fs-14px">
                              {{ item.user ? `${item.user.first_name} ${item.user.last_name}` : '' }}
                              <span v-if="item.order_id" class="ml-1 font-weight-bolder"> ({{ $t('Order No: ') }} {{ item.order ? item.order.store_order_invoice_id : item.order_id }}) </span>
                            </h6>
                            <p class="small mb-0 fs-14px">{{ $t('Comment on:') }} {{ formatDate(item.created_at) }}</p>
                          </b-media>
                          <p class="mt-2">
                            {{ item.review }}
                          </p>
                        </div>
                        <div class="col-md-3 d-flex fs-14px flex-wrap align-items-center justify-content-center">
                          <div>
                            <h2 class="text-center">
                              {{ item.rate }}
                            </h2>
                            <div class="mb-0">
                              <ul class="unstyled-list fs-14px list-inline">
                                <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                                  <feather-icon icon="StarIcon" size="16" :class="[{ 'fill-current': star <= item.rate }, star <= item.rate ? 'theme-color' : 'text-muted']" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.review_response" class="col-md-9">
                          <div class="ml-5">
                            <b-media class="pb-50 pt-50 fs-14px align-items-center">
                              <template #aside>
                                <b-avatar />
                              </template>
                              <h6 v-if="storeList" class="mb-0 fs-14px">
                                {{ storeList.store_name }}
                              </h6>
                              <p class="small mb-0">{{ $t('Comment on:') }} {{ formatDate(item.review_response.created_at) }}</p>
                            </b-media>
                            <p class="mt-2">
                              {{ item.review_response.review }}
                            </p>
                          </div>
                        </div>
                        <div v-if="item.review_response" class="fs-14px col-md-3 d-flex flex-wrap align-items-center justify-content-center">
                          <div>
                            <h2 class="text-center fs-14px">
                              {{ item.review_response.rate }}
                            </h2>
                            <div class="mb-0">
                              <ul class="unstyled-list fs-14px list-inline">
                                <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                                  <feather-icon
                                    icon="StarIcon"
                                    size="16"
                                    :class="[{ 'fill-current': star <= item.review_response.rate }, star <= item.review_response.rate ? 'theme-color' : 'text-muted']"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="lastReview" class="col-md-3 fs-14px store-shadow px-2 fs-14px py-2 store-left-margin">
                      <div class="recent-reviews">
                        <div class="d-flex justify-content-between align-items-center">
                          <h2>{{ $t('Newest') }}</h2>
                          <div class="wrap-next-prev">
                            <feather-icon class="cursor-pointer" icon="ChevronLeftIcon" @click="prevReview" />
                            <feather-icon class="cursor-pointer" icon="ChevronRightIcon" @click="nextReview" />
                          </div>
                        </div>
                        <p class="mt-2">“{{ lastReview.review }}”</p>
                        <b-media class="pb-50 pt-50 fs-14px mt-2 align-items-center">
                          <template #aside>
                            <b-avatar />
                          </template>
                          <h6>{{ lastReview.user ? `${lastReview.user.first_name} ${lastReview.user.last_name}` : '' }}</h6>
                        </b-media>
                        <div class="col-md-12 d-flex fs-14px align-items-center justify-content-center latest-review-wrapper">
                          <div class="w-100 d-flex align-items-center">
                            <h2 class="text-center mb-0 mr-1 text-white">
                              {{ lastReview.rate }}
                            </h2>
                            <div class="mb-0">
                              <ul class="unstyled-list list-inline mb-0">
                                <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                                  <feather-icon icon="StarIcon" size="16" :class="[{ 'fill-current': star <= lastReview.rate }, star <= lastReview.rate ? 'text-white' : 'text-muted']" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="d-flex">
                    <div class="col-md-8 store-shadow wrap-store-review mt-3">
                      <post-review />
                    </div>
                  </div> -->
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-inline">{{ $t('PHOTOS') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.photos" :type="'photos'" />
                <div v-if="otherPhotos && otherPhotos.length > 0 && optionsLoaded.photos" class="d-grid grid-mobile-columns">
                  <figure v-for="(item, index) in otherPhotos" :key="index" class="store-shadow" @mouseenter="$superApp.helper.zoomMagic" @mouseout="$superApp.helper.zoomOut">
                    <v-zoomer :zoomed.sync="$superApp.zoomed" :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom">
                      <b-img class="store-photos-tab store-shadow img-transition cursor-pointer" :src="$helpers.imageHelper(item.photo)" @click="showPopupImage(item.photo)" @error="$helpers.imageError" />
                    </v-zoomer>
                  </figure>
                </div>
              </b-tab>
              <b-tab v-if="isFoodCategory">
                <template #title>
                  <span class="d-inline">{{ $t('MENU') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.menu && !storeItemLoading" :type="'menu'" />
                <div v-if="storeList.photos && optionsLoaded.menu" class="d-grid grid-mobile-columns">
                  <figure
                    v-for="(item, index) in storeList.photos.filter(x => x.is_menu == 1)"
                    :key="index"
                    class="store-shadow-1"
                    @mouseenter="$superApp.helper.zoomMagic"
                    @mouseout="$superApp.helper.zoomOut"
                  >
                    <b-img class="store-photos-tab store-shadow img-transition cursor-pointer" :src="$helpers.imageHelper(item.photo)" @click="showPopupMenuImage(item.photo)" />
                  </figure>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </div>
    </template>
    <store-details-responsive v-if="$store.getters['app/windowSize'] <= 767" :that="this" @openVariations="openVariations" />
    <b-modal id="slider-modal" v-model="showPopup" :no-close-on-backdrop="true" hide-footer @hidden="showPopup = false">
      <modal-carousel ref="modalRef" :focus-image="imageIndex" :slide-options="allPhotos" />
    </b-modal>
    <b-modal id="slider-modal" v-model="showMenuPhotoModal" :no-close-on-backdrop="true" hide-footer @hidden="showMenuPhotoModal = false">
      <modal-carousel ref="menuModalRef" :focus-image="menuImageIndex" :slide-options="menuPhotos()" />
    </b-modal>
    <b-modal id="product-add-ons" v-model="showAddOns" :title="$t('Would you like to add any of this?')" :no-close-on-backdrop="true" @hidden="showAddOns = false" @ok="addItemsOns">
      <div v-if="productAddOns && productAddOns.length > 0" class="col-md-12">
        <b-form-checkbox-group v-model="selectedAddOns" name="event-filter" stacked>
          <b-form-checkbox v-for="item in productAddOns" :key="item.id" name="event-filter" :value="item.id" class="mb-1" :class="`custom-control-primary`">
            {{ item.addon_name }} at {{ storeList.currency_symbol }}{{ item.price }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </b-modal>
    <b-modal
      id="product-variations"
      v-model="showVariations"
      hide-footer
      :title="$t('Please choose the variations and addons')"
      :no-close-on-backdrop="true"
      @hidden="showVariations = false"
      @ok="setItemAddOnData(lastAddedProduct, false)"
    >
      <div v-if="variationImages && variationImages.length > 0" class="mb-1">
        <modal-carousel :slide-options="variationImages" />
      </div>
      <div v-if="lastAddedProduct" class="w-100 border-bottom mb-1">
        <h4>{{ lastAddedProduct.product ? lastAddedProduct.product.item_name : lastAddedProduct.item_name }}</h4>
        <p>{{ lastAddedProduct.product ? lastAddedProduct.product.item_description : lastAddedProduct.item_description }}</p>
      </div>
      <div v-if="productVariations && productVariations.length > 0" class="col-md-12">
        <div v-for="item in productVariations" :key="item.id">
          <b-form-radio-group v-if="!item.multiple" v-model="item.selectedVariations" :name="'variation-filter-' + item.id" stacked>
            <label v-if="item.variation_values && item.variation_values.length > 0" class="font-16 w-100 text-center">
              {{ item.title }} <span v-if="!item.optional" class="ml-2 small">({{ $t('Required') }})</span>
            </label>
            <b-form-radio v-for="data in item.variation_values" :key="data.id" :name="'variation-filter-' + item.id" :value="data.id" class="mb-1" :class="`custom-control-primary custom-radio-right`">
              <div class="w-100 d-flex justify-content-between border-bottom custom-div-wrapper">
                <span>{{ data.variation_option_id }}</span>
                <span v-if="canShowPrice(data.option_price)">{{ storeList.currency_symbol }}{{ data.option_price }}</span>
              </div>
            </b-form-radio>
          </b-form-radio-group>
          <b-form-checkbox-group v-else v-model="item.selectedVariations" :name="'variation-filter-' + item.id" stacked>
            <label v-if="item.variation_values && item.variation_values.length > 0" class="font-16 w-100 text-center">
              {{ item.title }} <span v-if="!item.optional" class="ml-2 small">({{ $t('Required') }})</span>
            </label>
            <b-form-checkbox
              v-for="data in item.variation_values"
              :key="data.id"
              :name="'variation-filter-' + item.id"
              :value="data.id"
              class="mb-1"
              :class="`custom-control-primary custom-radio-right`"
            >
              <div class="w-100 d-flex justify-content-between border-bottom custom-div-wrapper">
                <span>{{ data.variation_option_id }}</span>
                <span v-if="canShowPrice(data.option_price)">{{ storeList.currency_symbol }}{{ data.option_price }}</span>
              </div>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </div>
      <div v-if="productAddOns && productAddOns.length > 0" class="col-md-12 mt-2">
        <h5 class="text-center">
          {{ $t('Extra Additions') }} <span class="ml-25 small">({{ $t('Optional') }})</span>
        </h5>
        <b-form-checkbox-group v-model="selectedAddOns" name="event-filter" stacked>
          <b-form-checkbox v-for="item in productAddOns" :key="item.id" name="event-filter" :value="item.id" class="mb-1" :class="`custom-control-primary custom-radio-right`">
            <div class="w-100 d-flex justify-content-between border-bottom custom-div-wrapper">
              <span>{{ item.addon_name }}</span>
              <span v-if="canShowPrice(item.price)">{{ storeList.currency_symbol }}{{ item.price }}</span>
            </div>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
      <modal-footer :ok-text="'Add Items'" @hidden="showVariations = false" @ok="setItemAddOnData(lastAddedProduct, false)" />
    </b-modal>
    <b-modal v-model="repeatLastItem" :title="$t('Repeat last used customization?')" :no-close-on-backdrop="true" hide-footer @hidden="repeatLastItem = false">
      <div class="row">
        <div class="col-md-6 text-center my-1">
          <b-button variant="primary" @click="letMeChoose">
            {{ $t('Let me choose') }}
          </b-button>
        </div>
        <div class="col-md-6 text-center my-1">
          <b-button variant="primary" @click="repeatLastItemAdded">
            {{ $t('Repeat last') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <div class="text-center">
      <div v-if="storeList.settings" class="d-block">
        <img v-if="storeList.settings.fsaai_no" :src="require('@/assets/images/app-icons/fssai.jpg')" width="50" />
        <p v-if="storeList.settings.fsaai_no" class="mb-0">Licence No: {{ storeList.settings.fsaai_no }}</p>
        <p v-if="storeList.settings.registration_id" class="mb-0">Registration No: {{ storeList.settings.registration_id }}</p>
        <p v-if="storeList.settings.tax_id" class="mb-0">Tax Id: {{ storeList.settings.tax_id }}</p>
      </div>
      <p class="mb-0 mt-0 p-0">
        <b-link :to="{ path: termsConditions }">{{ $t('Terms and Conditions') }}</b-link>
        |
        <b-link to="/privacy-policy">{{ $t('Privacy Policy') }}</b-link>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import {
  BInputGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard,
  BLink,
  BImg,
  BCardBody,
  BCardText,
  BButton,
  BTab,
  BTabs,
  BFormSpinbutton,
  BMedia,
  BAvatar,
  BModal,
  BFormCheckboxGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification, useResponsiveAppLeftSidebarVisibility } from '@/@core/comp-functions/ui/app'
import LoginModal from '@core/layouts/components/app-navbar/components/LoginModal.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import moment from 'moment'
import { isUserLoggedIn } from '@/auth/utils'
import eventBus from '@/libs/event-bus'
// import PostReview from '@/views/shared/PostReview.vue'
import FormSpinButton from '@/views/shared/FormSpinButton.vue'
import { useUserUi } from './useUser'
import StoreSideBar from './store/StoreSideBar.vue'
import CustomCalender from './shared/CustomCalender.vue'
import NoDataAvailable from './shared/NoDataAvailable.vue'
import ModalCarousel from './shared/ModalCarousel.vue'
import StoreDetailsResponsive from './StoreDetailsResponsive.vue'
import UserOfferCards from './shared/UserOfferCards.vue'
import { isEmpty } from '@/utilities'

export default {
  components: {
    StoreSideBar,
    BInputGroup,
    FormSpinButton,
    BFormInput,
    ModalFooter,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormRadio,
    BCard,
    BLink,
    BImg,
    BCardBody,
    BCardText,
    BButton,
    BTab,
    BTabs,
    BFormSpinbutton,
    BMedia,
    BAvatar,
    CustomCalender,
    LoginModal,
    NoDataAvailable,
    BModal,
    ModalCarousel,
    StoreDetailsResponsive,
    UserOfferCards,
  },
  data() {
    return {
      weekDays: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      bookTable: {
        no_guests: 1,
        comment: '',
      },
      toggleTimings: false,
      storeId: '',
      reviewIndex: 0,
      mapRoute: '',
      showPopup: false,
      showMenuPhotoModal: false,
      searchQuery: '',
      addressInfo: [],
      itemView: 'grid-view',
      storeList: {},
      repeatLastItem: false,
      storeCurrentTimings: null,
      filteredStore: [],
      chooseManually: false,
      storeSlotInfo: [],
      isFoodCategory: false,
      optionsLoaded: {
        products: false,
        menu: false,
        overview: false,
        photos: false,
        reviews: false,
        photosCalled: false,
      },
      isInitialSearch: false,
      filterOptions: {
        selectedQuickFilter: '',
        quickFilters: [],
        selectedCategory: '',
        categoryFilters: [],
        showPriceRange: false,
        priceRange: '',
      },
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      startLocation: null,
      userOffers: [],
      imageIndex: null,
      menuImageIndex: null,
      mainPhoto: [],
      otherPhotos: [],
      allPhotos: [],
      lastAddedProduct: null,
      showAddOns: false,
      showVariations: false,
      productAddOns: [],
      productVariations: [],
      variationImages: [],
      selectedAddOns: [],
      spinDefaultObj: {},
      isStoreItemLoaded: true,
      storeItemLoading: false,
    }
  },
  computed: {
    termsConditions() {
      if (!this.storeList) return ''
      return `/user/store/terms-conditions/${this.$route.params.store}/${this.storeId}`
    },
    storeCategoryGroups() {
      if (!this.storeList) return []
      if (!this.storeList.categories) return []
      return this.storeList.categories
    },
    getUserInfo() {
      if (!window.SuperApp.getters.userInfo()) return {}
      return window.SuperApp.getters.userInfo()
    },
    isLoggedIn() {
      return isUserLoggedIn()
    },
    cartItems() {
      return this.$store.state['app-ecommerce'].cartItems
    },
    lastReview() {
      if (!this.storeList) return null
      if (!this.storeList.reviews) return null
      return this.storeList.reviews[this.reviewIndex]
    },
    storeTimings() {
      if (!this.storeList) return null
      if (!this.storeList.timings) return null
      return this.storeList.timings.find(x => x.store_day === this.currentDay)
    },
    currentDay() {
      return moment().format('ddd').toUpperCase()
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.beginSearch()
      },
    },
    'filterOptions.selectedQuickFilter': {
      handler() {
        this.optionsLoaded.products = false
        this.getStoreItems()
      },
    },
    'filterOptions.selectedCategory': {
      handler() {
        this.optionsLoaded.products = false
        this.getStoreItems()
      },
    },
    '$route.params.store': {
      handler() {
        this.resetData()
        this.init()
      },
    },
    itemView: {
      handler(nv) {
        let showFull = false
        if (nv === 'list-view') {
          showFull = true
        }
        this.filteredStore = this.filteredStore.map(i => ({ ...i, showFull }))
      },
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
  mounted() {
    eventBus.$on('location-searched', payload => {
      if (!payload.isCustom && payload.geometry) {
        this.getStoreItems(`latitude=${payload.geometry.location.lat()}&longitude=${payload.geometry.location.lng()}`)
      } else if (payload.isReset) {
        this.getStoreItems()
      } else if (payload.lat && payload.lng) {
        this.getStoreItems(`latitude=${payload.lat}&longitude=${payload.lng}`)
      }
    })
    this.init()
    window.that = this
    this.getStoreItems()
  },
  destroyed() {
    document.getElementById('app').classList.remove('store-web-new')
    eventBus.$off('location-searched')
  },
  methods: {
    moment,
    isEmpty,
    canShowPrice(price) {
      if (!price) return false
      // eslint-disable-next-line radix
      return parseInt(price) !== 0
    },
    getText(text) {
      return text.slice(0, 27)
    },
    canShowLabel(text) {
      return text && `${text}`.length > 27
    },
    storeTimingData(day) {
      const isExist = this.storeList.timings.find(x => x.store_day === day)
      if (isExist) {
        return isExist
      }
      return null
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm A')
    },
    storeCurrentTimingsInfo() {
      if (!this.storeList) return null
      if (!this.storeList.timings) return null
      if (this.storeTimings) return this.storeTimings
      return this.storeList.timings[0]
    },
    showAddOnsPopup() {
      this.showAddOns = true
      this.selectedAddOns = []
    },
    showVariationsPopup() {
      this.showVariations = true
    },
    addItemsOns() {
      this.addItemToCart(this.lastAddedProduct, this.selectedAddOns.join(','))
    },
    showShopClosed() {
      this.$swal({
        title: this.$t('Shop is closed'),
        text: this.$t('Currently, we are not accepting orders'),
        iconHtml: this.$helpers.swalIcon('schedule.png'),
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      })
    },
    openVariations(props) {
      const addOns = props.itemsaddon
      this.lastAddedProduct = props
      this.productAddOns = addOns
      const variations = props.product ? props.product.variation_prices : props.variation_prices
      const allVariations = props.product ? props.product.variations : props.variations
      this.productVariations = []
      this.productAddOns = []
      this.variationImages = Array.isArray(variations) ? variations.filter(x => x.images).flatMap(x => x.images) : []
      if (!isEmpty(props.picture)) {
        this.variationImages.push(props.picture)
      }
      if (variations && variations.length > 0) {
        const addedVariationsId = Array.from(new Set(variations.map(x => x.variation_id)))
        const variationsUsed = allVariations.filter(x => addedVariationsId.includes(x.id))
        const newVariations = []
        variationsUsed.forEach(item => {
          const dataObj = window.SuperApp.actions.cloneDeep(item)
          dataObj.variation_values = variations.filter(x => dataObj.variation_values.find(y => x.variation_option_id === y))
          // TODO: Just make it single select for now TOBE used in future
          dataObj.multiple = 0
          dataObj.selectedVariations = dataObj.multiple ? [] : 0
          dataObj.variation_values.forEach(vari => {
            // eslint-disable-next-line radix
            if (!vari.option_price) {
              vari.option_price = 0
            }
          })
          if (dataObj.variation_values.length > 0) {
            newVariations.push(dataObj)
          }
        })
        this.lastAddedProduct = props
        this.productVariations = newVariations
      }
      this.showVariationsPopup()
    },
    setItemAddOnData(props, checkVariations = true) {
      if (!isUserLoggedIn()) {
        this.$refs.loginModal.showModal()
        return
      }
      if (this.storeList.shopstatus === 'CLOSED') {
        this.showShopClosed()
        return
      }
      const addOns = props.product ? props.product.itemsaddon : props.itemsaddon
      const variations = props.product ? props.product.variation_prices : props.variation_prices
      const allVariations = props.product ? props.product.variations : props.variations
      let isAnyAvailable = false
      if (checkVariations) {
        this.productVariations = []
        this.productAddOns = []
        this.variationImages = Array.isArray(variations) ? variations.filter(x => x.images).flatMap(x => x.images) : []
      }
      if (variations && variations.length > 0 && checkVariations) {
        const addedVariationsId = Array.from(new Set(variations.map(x => x.variation_id)))
        const variationsUsed = allVariations.filter(x => addedVariationsId.includes(x.id))
        const newVariations = []
        variationsUsed.forEach(item => {
          const dataObj = window.SuperApp.actions.cloneDeep(item)
          dataObj.variation_values = variations.filter(x => dataObj.variation_values.find(y => x.variation_option_id === y))
          // TODO: Just make it single select for now TOBE used in future
          dataObj.multiple = 0
          dataObj.selectedVariations = dataObj.multiple ? [] : 0
          dataObj.variation_values.forEach(vari => {
            // eslint-disable-next-line radix
            if (!vari.option_price) {
              vari.option_price = 0
            }
          })
          if (dataObj.variation_values.length > 0) {
            newVariations.push(dataObj)
          }
        })
        this.lastAddedProduct = props
        this.productVariations = newVariations
        isAnyAvailable = true
      }
      if (addOns && addOns.length > 0 && checkVariations) {
        this.lastAddedProduct = props
        this.productAddOns = addOns
        isAnyAvailable = true
      }
      if (isAnyAvailable && checkVariations) {
        this.showVariationsPopup()
      } else {
        isAnyAvailable = false
      }
      if (!checkVariations || !isAnyAvailable) {
        this.addItemToCart(props, checkVariations ? '' : this.selectedAddOns.join(','))
      }
    },
    getStoreCuisines() {
      this.filterOptions.quickFilters = []
      const { storeCuisines } = useUserUi()
      if (!this.storeList.store_type_id) return
      storeCuisines(this.storeList.store_type_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (!(this.storeList && this.storeList.storetype && this.storeList.storetype.category === 'FOOD')) {
              data.responseData.forEach(x => {
                // eslint-disable-next-line eqeqeq
                if (!this.filterOptions.quickFilters.find(y => y.value == x.id)) {
                  this.filterOptions.quickFilters.push({
                    text: x.name,
                    value: x.id,
                  })
                }
              })
            }
            if (this.storeList && this.storeList.storetype && this.storeList.storetype.category === 'FOOD') {
              this.isFoodCategory = true
              if (!this.filterOptions.quickFilters.some(i => i.value === 'non-veg')) {
                this.filterOptions.quickFilters.unshift({
                  text: 'Non Veg',
                  value: 'non-veg',
                })
              }
              if (!this.filterOptions.quickFilters.some(i => i.value === 'pure-veg')) {
                this.filterOptions.quickFilters.unshift({
                  text: 'Pure Veg',
                  value: 'pure-veg',
                })
              }
            }
            if (!this.filterOptions.quickFilters.some(i => i.text === 'All')) {
              this.filterOptions.quickFilters.unshift({
                text: 'All',
                value: '',
              })
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    // eslint-disable-next-line no-unused-vars
    tabActivated(newTabIndex, oldTabIndex, event) {
      if (newTabIndex === 1) {
        this.getStoreItems()
      } else if ((newTabIndex === 2 && !this.isFoodCategory) || (newTabIndex === 3 && this.isFoodCategory)) {
        this.getStoreReview()
      } else if (!this.isFoodCategory && (newTabIndex === 3 || newTabIndex === 4) && this.isFoodCategory && (newTabIndex === 4 || newTabIndex === 5)) {
        this.getStorePhotos()
      }
    },
    mainPhotos() {
      if (!this.storeList) return null
      if (!this.storeList.photos) return null
      if (this.storeList.photos.find(x => x.is_main)) {
        return this.storeList.photos.find(x => x.is_main).photo
      }
      return null
    },
    otherPhoto() {
      if (!this.storeList) return []
      if (!this.storeList.photos) return []
      let allPhotoInfo = this.storeList.photos.filter(x => x.is_main === 0)
      if (this.storeList.products) {
        allPhotoInfo = allPhotoInfo.concat(
          this.storeList.products.map(x => ({
            photo: x.picture,
          })),
        )
      }
      allPhotoInfo = [...new Map(allPhotoInfo.map(item => [item.photo, item])).values()]
      return allPhotoInfo
    },
    allPhoto() {
      if (!this.storeList) return []
      if (!this.storeList.photos) return []
      return this.storeList.photos.map(x => x.photo)
    },
    menuPhotos() {
      if (!this.storeList) return []
      if (!this.storeList.photos) return []
      return this.storeList.photos.filter(i => i.is_menu == 1).map(x => x.photo)
    },
    getStoreReview() {
      if (this.optionsLoaded.reviews) {
        return
      }
      const { storeReview } = useUserUi()
      showLoader()
      storeReview({
        storeName: this.$route.params.store,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData && data.responseData.reviews) {
              this.storeList.reviews = data.responseData.reviews
              this.initStoreDetail()
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreItems(defaultLatLong = '') {
      if (this.optionsLoaded.products) {
        return
      }
      this.storeItemLoading = true
      showLoader()
      const { storeItems } = useUserUi()
      const extraFilters = []
      if (this.filterOptions.selectedQuickFilter) {
        extraFilters.push(`qfilter=${this.filterOptions.selectedQuickFilter}`)
      }
      if (this.filterOptions.selectedCategory) {
        extraFilters.push(`filter=${this.filterOptions.selectedCategory}`)
      }
      const selectedUserAddress = window.SuperApp.actions.selectedUserAddress()
      const isAddressExist = this.$store.state['app-user'].userAddressList.find(x => x.id === selectedUserAddress)
      if (isAddressExist && !defaultLatLong) {
        extraFilters.push(`latitude=${isAddressExist.latitude}&longitude=${isAddressExist.longitude}`)
      }
      if (defaultLatLong) {
        extraFilters.push(defaultLatLong)
      }
      if (this.filterOptions.priceRange) {
        extraFilters.push(`price_range=${this.filterOptions.priceRange}`)
      }
      storeItems({
        storeName: this.$route.params.store,
        extraFilters: extraFilters.length > 0 ? `?${extraFilters.join('&')}` : '',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData.categories) {
              this.storeList.categories = data.responseData.categories
              this.storeList.brands = data.responseData.brands
              if (Array.isArray(data.responseData.products)) {
                const categoryId = data.responseData.categories.map(x => x.id)
                data.responseData.products = data.responseData.products.filter(x => categoryId.includes(x.store_category_id))
              }
            }
            if (data.responseData.products) {
              this.storeList.products = data.responseData.products.map(x => {
                const brand = isEmpty(this.storeList.brands) ? {} : this.storeList.brands.find(b => b.id == x.brand_id)
                return {
                  ...x,
                  showFull: this.itemView === 'list-view',
                  brand,
                }
              })
            }
            if (data.responseData.storecart) {
              this.storeList.storecart = data.responseData.storecart
            }
            if (data.responseData.storetype) {
              this.storeList.storetype = data.responseData.storetype
            }
            this.initStoreDetail()
          }
          if (!this.isStoreItemLoaded) {
            this.isStoreItemLoaded = true
          }
          this.storeItemLoading = false
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStorePhotos() {
      if (this.optionsLoaded.photosCalled) {
        return
      }
      const { storePhotos } = useUserUi()
      showLoader()
      storePhotos({
        storeName: this.$route.params.store,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData && data.responseData[0] && data.responseData[0].photos) {
              this.optionsLoaded.photosCalled = true
              this.storeList.photos = data.responseData[0].photos
              this.initStoreDetail()
              this.mainPhoto = this.mainPhotos()
              this.otherPhotos = this.otherPhoto()
              this.allPhotos = this.allPhoto()
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    showPopupImage(index) {
      showLoader()
      this.imageIndex = index
      this.showPopup = true
      setTimeout(() => {
        this.$refs.modalRef.setActiveImage()
        setTimeout(() => {
          hideLoader()
        }, 250)
      }, 250)
    },
    showPopupMenuImage(index) {
      showLoader()
      this.menuImageIndex = index
      this.showMenuPhotoModal = true
      setTimeout(() => {
        this.$refs.menuModalRef.setActiveImage()
        setTimeout(() => {
          hideLoader()
        }, 250)
      }, 250)
    },
    goBack() {
      window.history.back()
    },
    prevReview() {
      if (!this.storeList) return null
      if (!this.storeList.reviews) return null
      if (this.reviewIndex > 0) this.reviewIndex -= 1
      return null
    },
    nextReview() {
      if (!this.storeList) return null
      if (!this.storeList.reviews) return null
      if (this.reviewIndex < this.storeList.reviews.length - 1) this.reviewIndex += 1
      return null
    },
    resetData() {
      this.reviewIndex = 0
      this.optionsLoaded = {
        products: false,
        menu: false,
        overview: false,
        photos: false,
        reviews: false,
        photosCalled: false,
      }
      this.isInitialSearch = false
      this.filteredStore = []
      this.storeSlotInfo = []
      this.storeList = {}
      this.filterOptions = {
        selectedQuickFilter: '',
        quickFilters: [],
        selectedCategory: '',
        categoryFilters: [],
        showPriceRange: false,
        priceRange: '',
      }
      this.searchQuery = ''
      this.addressInfo = []
    },
    init() {
      document.getElementById('app').classList.add('store-web-new')
      const { userAddressInfo } = useUserUi()
      showLoader()
      if (isUserLoggedIn()) {
        userAddressInfo()
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.addressInfo = data.responseData
            }
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      }
      this.getStoreList()
      if (this.$store.getters['app/windowSize'] <= 767) {
        this.getStorePhotos()
      }
      this.getStoreSlots()
      // this.getStorePhotos()
      // this.getStoreReview()
      // this.getStoreItems()
    },
    goToPromocode(props) {
      this.$router.push({
        path: `/user/store/details/${props.store.slug}`,
      })
    },
    getPromocodes() {
      const { promocodesMenu } = useUserUi()
      promocodesMenu(`?store_id=${this.storeList.id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userOffers = []
            if (data.responseData && data.responseData.length > 0) {
              this.userOffers = data.responseData.filter(x => x.store && x.store.slug === this.$route.params.store)
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    bookTableOnline(payload) {
      this.saveStoreSlot(payload)
    },
    getStoreSlots() {
      const { getStoreTimeslots } = useUserUi()
      getStoreTimeslots(this.$route.params.store)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeSlotInfo = data.responseData
            if (this.storeSlotInfo.timings && this.storeSlotInfo.timings[0] && this.storeSlotInfo.timings[0].store_day === 'ALL') {
              this.storeSlotInfo.timings = this.weekDays.map(x => ({
                store_day: x,
                store_end_time: this.storeSlotInfo.timings[0].store_end_time,
                store_start_time: this.storeSlotInfo.timings[0].store_start_time,
                store_id: this.storeSlotInfo.timings[0].store_id,
              }))
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveStoreSlot(payload) {
      if (!window.SuperApp.getters.userInfo()) {
        return
      }
      const bookingPayload = payload
      bookingPayload.store_id = this.storeList.id
      bookingPayload.order_type = 'dine_in'
      bookingPayload.user_id = window.SuperApp.getters.userInfo().id
      bookingPayload.no_guests = this.bookTable.no_guests
      bookingPayload.description = this.bookTable.comment
      showLoader()
      const { bookUserTableOnline } = useUserUi()
      const formData = new FormData()
      Object.keys(bookingPayload).map(x => formData.append(x, bookingPayload[x]))
      bookUserTableOnline(bookingPayload)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.getStoreSlots()
            this.bookTable.no_guests = 1
            this.bookTable.comment = ''
            if (this.$refs.bookingCalenderRef) {
              this.$refs.bookingCalenderRef.activeDate = new Date()
              this.$refs.bookingCalenderRef.activeTimeSlot = ''
            }
            if (this.$refs.bookingCalenderRefMobile) {
              this.$refs.bookingCalenderRefMobile.activeDate = new Date()
              this.$refs.bookingCalenderRefMobile.activeTimeSlot = ''
            }
            showSuccessNotification(this, 'Table booked successfully')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    goToSocial(link, target) {
      window.open(link, target)
    },
    openStoreMap() {
      window.open(`https://maps.google.com/?q=${this.storeList.latitude},${this.storeList.longitude}`)
    },
    openPhone() {
      window.open(`tel:+${this.storeList.country_code}${this.storeList.contact_number}`)
    },
    priceRangeChange(changeValue) {
      this.filterOptions.priceRange = changeValue
      this.getStoreList()
    },
    addItemToCart(product, addOns = null) {
      if (this.storeList.shopstatus === 'CLOSED') {
        this.showShopClosed()
        return
      }
      this.spinDefaultObj = window.SuperApp.actions.cloneDeep(product)
      const newProduct = product.product
        ? product
        : {
            product,
            value: 1,
            type: '+',
            fromSpin: true,
          }
      if (this.cartItems.find(x => x.store_id !== newProduct.product.store_id)) {
        this.$swal({
          title: this.$t('Items already in cart'),
          text: this.$t('Your cart contains items from another store. Would you like to reset your cart for adding items from this store?'),
          iconHtml: this.$helpers.swalIcon('notification.png'),
          showCancelButton: true,
          confirmButtonText: 'Yes, start refresh',
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const { removeProviderCart } = useUserUi()
            await removeProviderCart()
            this.makeCart(newProduct, addOns)
          }
        })
      } else {
        this.makeCart(newProduct, addOns)
      }
    },
    letMeChoose() {
      this.chooseManually = true
      this.setItemAddOnData(this.lastAddedProduct)
    },
    repeatLastItemAdded() {
      this.makeCart(this.lastAddedProduct)
    },
    multipleProduct(product) {
      if (this.cartItems && this.cartItems.filter(x => x.product && x.product.id === product.id).length > 0) {
        const quantity = this.cartItems
          .filter(x => x.product && x.product.id === product.id)
          .map(x => x.quantity)
          .reduce((a, b) => a + b, 0)
        return {
          quantity,
        }
      }
      return {
        quantity: 0,
      }
    },
    makeCart(product, addOns = null) {
      let isRemove = false
      let formData = new FormData()
      const newProduct = product.product
      formData.append('item_id', newProduct.id)
      let cartProduct = null
      if (this.cartItems) {
        const allCarts = this.cartItems.filter(x => x.product && x.product.id === newProduct.id)
        cartProduct = allCarts[allCarts.length - 1]
      }
      if (this.chooseManually) {
        cartProduct = null
      }
      if (this.addedProduct(newProduct) && product.value === 0) {
        if (cartProduct) {
          isRemove = true
        }
      }
      if (
        this.spinDefaultObj &&
        this.spinDefaultObj.type === '-' &&
        this.cartItems &&
        this.cartItems.filter(x => x.product && x.product.id === newProduct.id).length > 1 &&
        (document.getElementById('cart-dropdown-menu') || document.querySelectorAll('.store-cart-option-button #cart-dropdown-menu')[0])
      ) {
        if (document.getElementById('cart-dropdown-menu') && !document.getElementById('orderNowModal')) {
          document.getElementById('cart-dropdown-menu').click()
        } else if (document.querySelectorAll('.store-cart-option-button #cart-dropdown-menu')[0]) {
          document.querySelectorAll('.store-cart-option-button #cart-dropdown-menu')[0].click()
        }
        return
      }
      if (this.spinDefaultObj.type === '+' && !this.repeatLastItem && cartProduct && ((cartProduct.cartaddon && cartProduct.cartaddon.length) || cartProduct.product_data)) {
        this.lastAddedProduct = product
        this.repeatLastItem = true
        return
      }
      if (this.productVariations && this.productVariations.length > 0) {
        if (this.productVariations.filter(x => x.selectedVariations).length !== this.productVariations.length) {
          showDangerNotification(this, this.$t('Please choose all the variations'))
          return
        }
      }
      this.repeatLastItem = false
      if (this.addedProduct(newProduct) && !this.chooseManually) {
        this.addedProduct(newProduct).quantity = this.spinDefaultObj.type === '+' ? this.addedProduct(newProduct).quantity + 1 : this.addedProduct(newProduct).quantity - 1
      }
      formData.append('qty', this.addedProduct(newProduct) && !this.chooseManually ? this.addedProduct(newProduct).quantity : 1)
      if (this.cartItems && this.cartItems.length > 0) {
        if (cartProduct) {
          formData.append('repeat', 1)
        }
      } else {
        formData.append('repeat', 0)
      }
      if (cartProduct) {
        if (isRemove) {
          formData = new FormData()
        }
        formData.append('cart_id', cartProduct.id)
      }
      if (this.chooseManually) {
        if (formData.has('repeat')) {
          formData.delete('repeat')
        }
        if (formData.has('cart_id')) {
          formData.delete('cart_id')
        }
        if (formData.has('qty')) {
          formData.delete('qty')
        }
        formData.append('qty', 1)
      }
      if (addOns) {
        formData.append('addons', addOns)
      }
      this.chooseManually = false
      if (this.productVariations && this.productVariations.length > 0) {
        this.productVariations.forEach(x => {
          if (!Array.isArray(x.selectedVariations)) {
            const selectedVariation = x.variation_values.find(y => y.id === x.selectedVariations)
            if (selectedVariation) {
              formData.append(`variation_option[${x.title}]`, selectedVariation.variation_option_id)
            }
          } else {
            x.selectedVariations.forEach(data => {
              // eslint-disable-next-line eqeqeq
              const selectedVariation = x.variation_values.find(y => y.id == data)
              if (selectedVariation) {
                formData.append(`variation_option[${x.title}]`, selectedVariation.variation_option_id)
              }
            })
          }
        })
      }
      this.showVariations = false
      showLoader()
      this.$store
        .dispatch(`app-ecommerce/${isRemove ? 'removeFromCart' : 'updateQuantity'}`, formData)
        .then(response => {
          if (response.data.statusCode === '200') {
            const items = response.data.responseData.carts
            this.productVariations = []
            this.productAddOns = []
            this.selectedAddOns = []
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', items)
            this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', response.data.responseData)
            showSuccessNotification(this, 'Cart updated successfully')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreList() {
      showLoader()
      const { userStoreDetail } = useUserUi()
      const extraFilters = []
      if (this.filterOptions.selectedQuickFilter) {
        extraFilters.push(`qfilter=${this.filterOptions.selectedQuickFilter}`)
      }
      if (this.filterOptions.selectedCategory) {
        extraFilters.push(`filter=${this.filterOptions.selectedCategory}`)
      }
      if (this.filterOptions.priceRange) {
        extraFilters.push(`price_range=${this.filterOptions.priceRange}`)
      }
      userStoreDetail({
        storeName: this.$route.params.store,
        query: extraFilters.join('&'),
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            Object.keys(data.responseData).forEach(x => {
              this.storeList[x] = data.responseData[x]
            })
            if (this.storeList.timings && this.storeList.timings[0] && this.storeList.timings[0].store_day === 'ALL') {
              this.storeList.timings = this.weekDays.map(x => ({
                store_day: x,
                store_end_time: this.storeList.timings[0].store_end_time,
                store_start_time: this.storeList.timings[0].store_start_time,
                store_id: this.storeList.timings[0].store_id,
              }))
            }
            this.storeId = this.storeList.id
            this.getPromocodes()
            this.mainPhoto = this.mainPhotos()
            this.otherPhotos = this.otherPhoto()
            this.allPhotos = this.allPhoto()
            const tempList = window.SuperApp.actions.cloneDeep(this.storeList)
            this.storeList = tempList
            this.getStoreCuisines()
            this.initStoreDetail()
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    initStoreDetail() {
      if (!this.storeList.reviews) {
        this.storeList.reviews = []
      }
      if (!this.storeList.products) {
        this.storeList.products = []
      }
      if (!this.storeList.photos) {
        this.storeList.photos = []
      }
      if (!this.storeList.categories) {
        this.storeList.categories = []
      }
      if (!this.storeList.storecart) {
        this.storeList.storecart = []
      }
      window.storeList = this.storeList
      if (!this.filterOptions.categoryFilters.find(x => x.text !== 'All')) {
        this.filterOptions.categoryFilters.push({ text: 'All', value: '' })
        this.storeList.categories.forEach(x => {
          // eslint-disable-next-line eqeqeq
          if (!this.filterOptions.categoryFilters.find(y => y.value == x.id)) {
            this.filterOptions.categoryFilters.push({
              text: x.store_category_name,
              value: x.id,
            })
          }
        })
      }
      if (this.storeList.overviews) {
        this.optionsLoaded.overview = true
      }
      if (this.storeList.products && this.storeList.products.length > 0) {
        this.optionsLoaded.products = true
      }
      if (this.storeList.reviews && this.storeList.reviews.length > 0) {
        this.optionsLoaded.reviews = true
      }
      if (this.storeList.photos && this.storeList.photos.filter(x => x.is_menu === 0).length > 0) {
        this.optionsLoaded.photos = true
      }
      if (this.storeList.photos && this.storeList.photos.filter(x => x.is_menu === 1).length > 0) {
        this.optionsLoaded.menu = true
      }
      this.beginSearch()
      this.storeCurrentTimings = this.storeCurrentTimingsInfo()
      if (!this.isInitialSearch) {
        this.isInitialSearch = true
        this.mapRoute = `https://maps.google.com/maps?q=${this.storeList.latitude}, ${this.storeList.longitude}&z=15&output=embed`
      }
    },
    isAddOnsAvailable(product) {
      return this.isAddedToCart(product) && product.itemsaddon && product.itemsaddon.length > 0
    },
    isAddedToCart(product) {
      return this.cartItems.find(x => x.product && x.product.id === product.id)
    },
    addedProduct(product) {
      const products = this.cartItems.filter(x => x.product && x.product.id === product.id)
      return products[products.length - 1]
    },
    beginSearch() {
      if (this.searchQuery) {
        this.filteredStore = this.storeList.products.filter(x => x.item_name && x.item_name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      } else {
        this.filteredStore = this.storeList.products
      }
    },
    copyUrl() {
      if (window.SuperApp.helper.copyUrl()) {
        showSuccessNotification(this, 'Link copied successfully')
      } else {
        showDangerNotification(this, 'Failed to copy link')
      }
      window.SuperApp.actions.sharePage('MOM')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/store-details.scss';
</style>

<style lang="scss">
.modal-body .carousel{
  background-color: #969696;
}
</style>
<style lang="scss" scoped>
.wrap-store-review .reviews:last-child {
  border-bottom: 0 !important;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.result-toggler {
  height: 40px;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
  padding-top: 0;
}

.ecommerce-application .list-view .ecommerce-card .item-img img {
  border-top-right-radius: 0;
}

.list-view .pt-pb {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
</style>

<style scoped>
@media only screen and (max-width: 767px) {
  .ecommerce-application .list-view .ecommerce-card .item-img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .list-view .pt-pb {
    padding-top: 0 !important;
  }
}
</style>

<style>
.border-radius-10 {
  border-radius: 10px;
}
@media only screen and (max-width: 767px) {
  .user-store-details .store-padding {
    padding-top: 10px;
  }

  .store-ui-landing .wrap-sidebar-filters .card {
    box-shadow: none;
  }

  .store-ui {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.promocodes-wrapper-store .user-offer-cards img {
  object-fit: cover !important;
}
</style>

<style>
#product-variations .custom-control-primary .custom-control-label {
  width: 100%;
}

#product-variations .modal-body {
  max-height: 75vh;
  overflow: auto;
}

#product-variations .custom-control-primary .custom-div-wrapper {
  padding-bottom: 5px !important;
}

@media only screen and (max-width: 767px) {
  .store-ui .store-image-section-height {
    height: auto;
    margin-top: 10px;
  }

  .store-ui .mobile-pad-0 {
    padding: 0;
  }

  .store-ui .wrap-grid-info {
    margin-top: 10px;
  }

  .store-ui .address-lines p {
    font-size: 15px;
  }

  .store-ui .wrap-address-line-last {
    display: block !important;
  }

  .store-ui .social-icon-height {
    height: 28px;
  }

  .profile_page_label_icon {
    height: 40px;
  }

  .store-ui .wrap-store-extra-description {
    padding: 15px;
  }

  .store-ui .wrap-book-a-table .wrap-store-options {
    width: 100% !important;
  }

  .store-ui .close-filter-button {
    display: block;
  }
}

body #product-variations.modal .carousel-item img,
body #product-variations.modal .carousel-item {
  height: 298px;
}
.left-image {
  height: 600px;
  width: 100%;
}
.right-side-images {
  width: 100%;
  height: 298px;
}
.grid-row-gap-5 {
  grid-row-gap: 5px;
}
.pl-5px {
  padding-left: 5px !important;
}
.fs_110x {
  font-size: 110% !important;
}
.store-logo{border-radius: 50%; height: 80px; width: 80px;}
</style>


<style lang="scss">
.user-login-required.apps-store-details {
  .navbar {
    display: none !important;
  }
  .horizontal-layout.mobile-view-class.navbar-floating:not(.blank-page) .app-content {
    padding-top: 0 !important;
  }
  .horizontal-layout.navbar-floating:not(.blank-page) .app-content {
    padding-top: 0 !important;
  }
  .store-image-section-height {
    margin-top: 0 !important;
  }
  .t_and_c_prefix_text {
    display: none;
  }
}
.btn-store-profile{

}
</style>

<style lang="scss" scoped>
.store_title {
  font-size: 2rem;
  font-weight: 900;
  color: #000;
  text-transform: uppercase;
}
.store_location {
  width: 100%;
  font-size: 18px;
}
.store_phone {
  font-size: 18px;
  margin-top: 8px;
}
.main_card {
  border-radius: 10px;
  background-color: #F3F3F3;
}
.tab_card {
  border-radius: 10px;
  background-color: #F3F3F3;
}
.profile_page_label_icon{
  height: 30px !important;
}
@media screen and (min-width: 768px) {
  .profile_page_label_icon {
    height: 40px !important;
  }
}
</style>